import { Component, Input } from '@angular/core';
import { FooterComponent, FooterComponentData } from '../footer.model';

@Component({
  selector: 'app-maticx-footer',
  templateUrl: './maticx-footer.component.html',
  styleUrls: ['./maticx-footer.component.scss'],
})
export class MaticxFooterComponent implements FooterComponent {
  @Input() data: FooterComponentData;
}
