export const MIN_LATEST_TRIPS_DAYS = 30;
export const DEVICE_GPS_UPDATE_TIMER_MIN = 3;
export const TRIP_STATUS_UPDATE_TIMER_MIN = 3;
export const LIVE_VIEW_TAB = ['LIVE VIEW', 'FLEET VIEW'];
export const LIVE_FILTER_OPTION = [
  {
    id: 'assetId',
    label: 'Asset',
  },
  {
    id: 'tags',
    label: 'Tags',
  },
];

export const EVENT_LIST_TABLE_HEADER = ['eventName', 'actions'];
export const EVENT_LIST_PAGE_SIZE = 10;

export enum AssetLiveViewState {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Parked = 'PARKED',
}
