import { MILES_TO_KILOMETERS_CONVERSION } from '@app-core/constants/constants';
import { MIN_DISTANCE, SAFE_THRESHOLD, UNSAFE_THRESHOLD } from '@app-home/constants/home.constants';
import { AssetAutoCompleteResponseObject, ReportTypeKey } from '@app-core/models/core.model';
import { TIME } from '@app-reports/constants/reports.constants';

export class DownloadFleetSafetyReportsParams {
  public startDate: string;
  public endDate: string;
  public fleetId: string;
  public safeThreshold: number;
  public unsafeThreshold: number;
  public unit: string;
  public minDistance: number;
  public language: string;
  public includeInsignificantPoints: string;

  constructor({ startDate, endDate, fleetId, unit, language, includeInsignificantPoints }) {
    this.startDate = startDate;
    this.endDate = endDate;
    this.fleetId = fleetId;
    this.language = language;
    this.includeInsignificantPoints = includeInsignificantPoints;
    if (unit === 'Miles') {
      this.unit = 'mi';
      this.safeThreshold = SAFE_THRESHOLD;
      this.unsafeThreshold = UNSAFE_THRESHOLD;
      this.minDistance = MIN_DISTANCE;
    } else {
      this.unit = 'km';
      this.safeThreshold = Number((SAFE_THRESHOLD / MILES_TO_KILOMETERS_CONVERSION).toFixed(2));
      this.unsafeThreshold = Number((UNSAFE_THRESHOLD / MILES_TO_KILOMETERS_CONVERSION).toFixed(2));
      this.minDistance = Number((MIN_DISTANCE * MILES_TO_KILOMETERS_CONVERSION).toFixed(2));
    }
  }
}
export class DownloadCoachingSessionReportsParams {
  public fleetId?: string;
  public language?: string;
}
export interface ExportHistoryRouteParams {
  reportType: ReportTypeKey;
}

export interface AsyncReportsParams {
  reportType?: ReportTypeKey;
  reportStatus?: string;
  startDate?: string;
  endDate?: string;
  fleetId?: string;
  downloadRequestId?: string;
  limit?: number;
  offset?: number;
}

export interface AsyncReportRowPayload {
  after: string;
  before: string;
  reportFormat: 'CSV' | 'PDF';
  eventType: string;
}

export interface UserMetaData {
  name: string;
  email: string;
  userType: string;
  userId: string;
}

export interface AsyncReportRow {
  status: string;
  reportType: ReportTypeKey;
  downloadUrl: string;
  requestedAt: string;
  downloadRequestId?: string;
  payload: AsyncReportRowPayload;
  requestedUserMetadata: UserMetaData;
  reportSizeInBytes: number;
}

export interface AsyncReportsResponse {
  rows: AsyncReportRow[];
  limit: number;
  skip: number;
  totalCount: number;
}
export interface DriverCoachingEffectivenessResponse {
  driverId: string;
  coachingSessionId: number;
  driverName: string;
  coachName: string;
  sessionDuration: number;
  totalDistance: number;
  totalViolations: number;
  mostFrequentEventType: string;
  leastFrequentEventType: string;
  scoreDiff: number;
  score: number;
  sessionCreatedAt: string;
  eventWiseCoachingEffectiveness: EventWiseCoachingEffectiveness[];
  previousCoachingSessions: PreviousCoachingSession[];
  incidentRatioTrend: IncidentRatioTrend[];
  originalMostFrequentEventType?: string;
  originalLeastFrequentEventType?: string;
}

export interface StatsData {
  driverId: string;
  coachingSessionId: number;
  driverName: string;
  coachName: string;
  sessionDuration: number;
  totalDistance: number;
  totalViolations: number;
  mostFrequentEventType: string;
  leastFrequentEventType: string;
  scoreDiff: number;
  score: number;
  originalMostFrequentEventType?: string;
  originalLeastFrequentEventType?: string;
}

export interface IncidentRatioTrend {
  date: string;
  incidentRatio: number;
}

export interface EventWiseCoachingEffectiveness {
  eventType: string;
  beforeCoaching: number;
  afterCoaching: number;
  fleetAverage: number;
  originalEventType?: string;
}

export interface PreviousCoachingSession {
  coachingSessionId: number;
  coachedAt: string;
}

//scheduled report constants
export enum FormAction {
  Create = 'Create',
  Edit = 'Edit',
  Duplicate = 'Duplicate',
}

type ScheduledReportType =
  | 'FLEET_SAFETY'
  | 'FLEET_HEALTH_EVENTS'
  | 'FLEET_CAMERA_EVENTS'
  | 'FLEET_DEVICE_PING'
  | 'TRIPS'
  | 'COACHING_SESSION'
  | 'PRIVACY_MODE'
  | 'EVENT_LIST';

export interface FormData {
  scheduleId: string;
  scheduleName: string;
  reportType: ScheduledReportType;
  reportSubtype: string;
  filterType?: string;
  driverId?: string;
  asset: AssetAutoCompleteResponseObject | { assetId: string };
  duration: number;
  frequency: 'DAILY' | 'WEEKLY' | 'MONTHLY' | 'IMMEDIATE';
  sendDay: number;
  sendTime: (typeof TIME)[number];
  email: string[];
  tagIds?: string[];
}

export interface CreateScheduleRouteParams {
  action: FormAction;
  scheduleId?: string;
  reportType?: ScheduledReportType;
  includeInsignificantTrips?: boolean;
  includeInsignificantPoints?: boolean;
}

export interface GetScheduledReportsParams {
  limit: number;
  offset: number;
  reportType?: string;
  scheduleName?: string;
}

export interface GetScheduledReportsResponse {
  limit: number;
  skip: number;
  rows?: ScheduledReportRow[];
  totalCount: number;
}

export interface ScheduledReportRow {
  scheduleId: string;
  scheduleName: string;
  reportType: ScheduledReportType;
  scheduleConfiguration: ScheduleConfig;
  active: boolean;
  requestedUserMetadata: UserMetaData;
  createdAt: string;
  updatedAt: string;
}
export interface CreateScheduleBody {
  scheduleName: string;
  reportType: ScheduledReportType;
  scheduleConfiguration: ScheduleConfig;
}

export interface PatchScheduleBody {
  scheduleName?: string;
  reportType: ScheduledReportType;
  scheduleConfiguration?: ScheduleConfig;
  active?: boolean;
}

export interface ScheduleConfig {
  frequency?: 'DAILY' | 'WEEKLY' | 'MONTHLY' | 'IMMEDIATE';
  sendTimeUTC?: (typeof TIME)[number];
  reportDurationRange?: number;
  dayOfTheSchedule?: number;
  additionalEmails?: string[];
  timezoneId?: string;
  localTimezoneId?: string;
  tagIds?: string[];
  eventType?: string;
  driverId?: string;
  assetId?: string;
}

export type Timezone = {
  timezoneId?: string;
  localTimezoneId?: string;
};
