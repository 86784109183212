import { Injectable, OnDestroy } from '@angular/core';
import { init } from 'commandbar';
import { environment } from '@env/environment';
import { COMMAND_BAR_ORG_ID } from '@app-core/constants/constants';
import { CommandBarData } from '@app-core/models/core.model';

@Injectable({
  providedIn: 'root',
})
export class CommandbarService implements OnDestroy {
  constructor() {
    !window.lm?.disableCommandBar && init(COMMAND_BAR_ORG_ID);
  }

  public bootCommandBar(userId: string): void {
    if (window.CommandBar && !window.lm?.disableCommandBar) {
      window.CommandBar.boot(userId);
    }
  }

  public openCommandBar() {
    if (window.CommandBar) {
      window.CommandBar.open();
    }
  }

  //to send the details of user
  setUserProperties(properties: CommandBarData) {
    const production = environment.production;
    const env = environment.env;
    if (window.CommandBar) {
      window.CommandBar.setUserProperties(properties);
      window.CommandBar.addMetadata('production', production, true);
      window.CommandBar.addMetadata('env', env, true);
    }
  }

  closeCommandBar(): void {
    if (window.CommandBar) {
      window.CommandBar.shutdown();
    }
  }
  ngOnDestroy(): void {
    window.CommandBar.shutdown();
  }
}
