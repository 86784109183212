import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { DurationRange } from '@app-core/models/core.model';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { CLIENT_CONFIG } from '@config/config';
import { AssetAutoCompleteResponseObject, RequestDownloadReportBody } from '@app-core/models/core.model';
import { finalize, takeUntil } from 'rxjs/operators';
import { AsyncDownloadsService } from '@app-core/services/async-downloads/async-downloads.service';
import { AsyncReportType } from '@app-core/constants/constants';
import { Subject } from 'rxjs';
import { SnackBarService } from '@app-core/services/snackbar/snack-bar.service';
import { TranslateService } from '@ngx-translate/core';
import { HttpErrorResponse } from '@angular/common/http';
import { DateService } from '@app-core/services/date/date.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

interface DownloadReportBody extends RequestDownloadReportBody {
  dateFormat?: string;
  language?: string;
  startDate?: string;
  endDate?: string;
  assetId?: string;
  driverId?: string;
  fleetId?: string;
}

@Component({
  selector: 'app-download-report',
  templateUrl: './download-report.component.html',
  styleUrls: ['./download-report.component.scss'],
})
export class DownloadReportComponent implements OnInit, OnDestroy {
  public durationRange: DurationRange;
  public entityType: string[] = ['asset', 'driver'];
  public filterTypeList = [
    { label: 'Driver', value: 'driverFilter' },
    { label: 'Asset', value: 'assetFilter' },
  ];
  public csvDownloadSuccess: boolean | null = null;
  public csvDownloadError: boolean | null = null;
  public downloadReportLoader = false;
  public selectedFilterType = 'driverFilter';
  public form: FormGroup;
  public _driverList: any[] = [];
  public clientConfig = CLIENT_CONFIG;
  public fleetId: string;
  private ngUnsubscribe = new Subject<void>();
  private ALL_DRIVERS_ID = 'All Drivers';
  public reportType: string;

  constructor(
    private fb: FormBuilder,
    private asyncDownloadsService: AsyncDownloadsService,
    private translate: TranslateService,
    private snackBarService: SnackBarService,
    private dateService: DateService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.reportType = data.reportType;
  }

  ngOnInit(): void {
    this.durationRange = this.getStartAndEndTime(7);
    this.form = this.fb.group({
      days: [7, [Validators.required]],
      startDate: [this.durationRange.startDate, [Validators.required]],
      endDate: [this.durationRange.endDate, [Validators.required]],
      ...(this.clientConfig?.isAssetCentric ? { filterType: ['assetFilter'] } : { filterType: ['driverFilter'] }),
      driverId: [this.ALL_DRIVERS_ID],
      asset: [],
      tagIds: [],
    });
  }

  public durationChange(durationRange: DurationRange) {
    const { days, startDate, endDate } = durationRange;
    if (days && startDate && endDate) {
      this.form?.patchValue({
        days: days,
        startDate: startDate,
        endDate: endDate,
      });
    }
  }

  private getStartAndEndTime(noOfDays) {
    const { from, to } = this.dateService.getDateRange(noOfDays);
    return {
      days: noOfDays,
      startDate: from,
      endDate: to,
    };
  }

  public downloadAsyncReport() {
    this.csvDownloadSuccess = null;
    this.csvDownloadError = null;
    this.downloadReportLoader = true;

    const { startDate, endDate, driverId, asset, filterType, tagIds } = this.form.value;
    const now = new Date();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const seconds = now.getSeconds();
    const milliSeconds = now.getMilliseconds();

    let selectedDriverId = filterType === 'driverFilter' && driverId !== this.ALL_DRIVERS_ID ? driverId : undefined;
    let selectedAssetId = filterType === 'assetFilter' ? asset?.assetId : undefined;
    const from = this.dateService.toDaysStartISO(new Date(startDate.setHours(hours, minutes, seconds, milliSeconds)));
    const to = this.dateService.toDaysEndISOPlusOne(new Date(endDate.setHours(hours, minutes, seconds, milliSeconds)));

    const body: DownloadReportBody = {
      reportType: this.reportType,
      reportFormat: 'CSV',
      fleetId: this.fleetId,
      startDate: from,
      endDate: to,
      driverId: selectedDriverId,
      assetId: selectedAssetId,
      ...(this.reportType === 'EVENT_LIST' && tagIds?.length ? { tagIds } : {}),
    };
    this.asyncDownloadsService
      .requestDownloadReport(body)
      .pipe(
        finalize(() => {
          this.downloadReportLoader = false;
        }),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(
        () => {
          this.csvDownloadSuccess = true;
        },
        (err: HttpErrorResponse) => {
          this.csvDownloadSuccess = false;
          this.csvDownloadError = true;
          if (err?.status === 409) {
            this.snackBarService.failure(this.translate.instant('tripListComponentTripDuplicate'));
          } else {
            this.snackBarService.failure(this.translate.instant('tripListComponentTripsDownloadFailure'));
          }
        }
      );
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public navigateToDownloads(): void {
    if (this.reportType === 'PRIVACY_MODE') {
      this.asyncDownloadsService.navigateToExportHistory(AsyncReportType.privacyModeEventsReport.key);
    } else if (this.reportType === 'EVENT_LIST') {
      this.asyncDownloadsService.navigateToExportHistory(AsyncReportType.eventListReport.key);
    }
  }

  public driverChanged(driverId: string = ''): void {
    this.form.patchValue({ driverId });
  }

  public assetChanged(asset: AssetAutoCompleteResponseObject): void {
    this.form.patchValue({ asset });
  }
  public selectedTags(tags: any[]): void {
    this.form.patchValue({ tagIds: tags });
  }
}
