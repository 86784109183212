import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { API } from '@app-core/constants/api.constants';
import {
  ReportTypeKey,
  RequestDownloadReportBody,
  ScheduledReportAdditionalDetails,
  ScheduledReportTypeKey,
  Timezone,
} from '@app-core/models/core.model';
import { FormAction } from '@app-reports/models/reports.model';
import { DataService } from '@app-core/services/data/data.service';
import * as momentTz from 'moment-timezone';

@Injectable({
  providedIn: 'root',
})
export class AsyncDownloadsService {
  constructor(private http: HttpClient, private router: Router, private dataService: DataService) {}

  public requestDownloadReport(body: RequestDownloadReportBody, addTimezoneOffset: boolean = true) {
    if (!body.reportFormat) {
      body = { ...body, reportFormat: 'CSV' };
    }

    if (addTimezoneOffset) {
      body = { ...body, ...this.getTimezoneOffset() };
    }

    return this.http.post(API.ASYNC_REPORTS, body);
  }

  public navigateToExportHistory(reportType: ReportTypeKey) {
    this.router.navigate(['/reports'], {
      queryParams: {
        tab: 'export-history',
        reportType: reportType,
      },
    });
  }

  public navigateToCreateSchedule(reportType: ScheduledReportTypeKey, additionalDetails?: ScheduledReportAdditionalDetails) {
    this.router.navigate(['/reports/create-schedule'], {
      queryParams: {
        action: FormAction.Create,
        reportType: reportType,
        ...additionalDetails,
      },
    });
  }

  private getTimezoneOffset(): Timezone {
    const selectedTimezone = this.dataService._currentTimeZone.getValue();
    if (selectedTimezone === 'Local') {
      const localTimezone = momentTz.tz.guess(true);
      return { localTimezoneId: localTimezone };
    } else {
      return { timezoneId: selectedTimezone };
    }
  }
}
