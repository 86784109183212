import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { State } from '@app-shared/reducers/sidenavigation-config.reducer';
import { Store } from '@ngrx/store';
import { takeUntil, map } from 'rxjs/operators';
import { merge, Subject } from 'rxjs';
import { getSideNavigationConfigState } from '@app-shared/reducers';

import { MAPBOX_ACCESS_TOKEN } from '@app-request-video/constants/request-video.constants';
import mapboxgl from 'mapbox-gl';
import { MAPBOX_DEFAULT_LAYER, US_CENTER_LAT_LNG } from '@app-core/constants/constants';
import * as turf from '@turf/turf';
import { TripDetailsService } from '@app-trip-details/services/trip-details.service';

mapboxgl.accessToken = MAPBOX_ACCESS_TOKEN;

interface Coordinates {
  latitude: number;
  longitude: number;
}

@Component({
  selector: 'app-map-box-map',
  templateUrl: './map-box-map.component.html',
  styleUrls: ['./map-box-map.component.scss'],
})
export class MapBoxMapComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @Output() markerClick = new EventEmitter<any>();
  @Input() public markerList: any[] = [];
  @Input() public latlonList: any[] = [];
  @Input() private mapInitialCoordinates: Coordinates = {} as Coordinates;
  @Input() public mapId = '';
  @Input() public mapWidth = '100vw';
  @Input() public customMapOptions: any = {};
  @Input() public bearing: number;
  @Input() public speed: number;
  @Input() public highlightedPathList: any[] = [];
  @Input() public loadingTripDetails = true;

  public currentLayer = MAPBOX_DEFAULT_LAYER;
  public map: mapboxgl.Map;

  private ngUnsubscribe: Subject<void> = new Subject<void>();
  private polylineGeoJson: any;
  private internalMarkerList: any[] = [];
  private isSideNavOpen = false;
  private currentWindowWidth: number;

  constructor(public translate: TranslateService, private store: Store<State>, private tripDetailsService: TripDetailsService) {}

  public ngOnInit(): void {
    this.subscribeForSideNavAndViewTypeChange();
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (!this.map) {
      setTimeout(() => {
        this.ngOnChanges(changes);
      }, 100);
      return;
    }
    // adjust mapwidth from 100vw to required width after load is loaded
    if (changes.loadingTripDetails && !this.loadingTripDetails) {
      this.adjustMapWidth(this.currentWindowWidth);
    }
    if (changes.markerList && changes.markerList.currentValue) {
      this.addMarker(this.markerList);
    }
    if (changes.latlonList && changes.latlonList.currentValue?.length) {
      this.addPath(this.latlonList);
    }
  }

  public ngAfterViewInit() {
    setTimeout(() => {
      this.loadMap();
    }, 50);
  }

  public ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private loadMap() {
    const { latitude = US_CENTER_LAT_LNG.latitude, longitude = US_CENTER_LAT_LNG.longitude } = this.mapInitialCoordinates || {};
    this.map = new mapboxgl.Map({
      container: this.mapId,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [longitude, latitude],
      zoom: 8,
    });
    // Add the navigation control to the map
    const nav = new mapboxgl.NavigationControl();
    this.map.addControl(nav, 'top-left');
    this.map.addControl(new mapboxgl.FullscreenControl(), 'top-left');
  }

  private subscribeForSideNavAndViewTypeChange() {
    const sidenavConfig$ = this.store.select(getSideNavigationConfigState).pipe(map((value) => ({ source: 'sidenav', value })));

    const viewType$ = this.tripDetailsService.tripDetailsViewType.pipe(map((value) => ({ source: 'viewType', value })));

    merge(sidenavConfig$, viewType$)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(({ source, value }) => {
        if (source === 'sidenav') {
          const { currentWindowWidth, isSideNavOpen } = value as State;
          this.currentWindowWidth = currentWindowWidth;
          this.isSideNavOpen = isSideNavOpen;
        }
        if (this.map) {
          this.adjustMapWidth(this.currentWindowWidth);
        }
      });
  }

  private removeAllMarkers() {
    this.internalMarkerList.forEach((marker) => {
      const handler = marker.getElement().__handlerRef;
      if (handler) {
        marker.getElement().removeEventListener('mouseenter', handler);
        marker.getElement().removeEventListener('mouseleave', handler);
        marker.getElement().removeEventListener('click', handler);
        delete marker.getElement().__handlerRef;
      }
      marker.remove();
    });
    this.internalMarkerList = [];
  }

  private handleMarkerEvent(event: Event, index: number) {
    const marker = this.internalMarkerList[index];
    if (!marker) {
      return;
    }

    switch (event.type) {
      case 'click': {
        this.markerClick.emit(marker);
        break;
      }

      case 'mouseenter':
      case 'mouseleave': {
        marker.togglePopup();
        break;
      }

      default:
        break;
    }
  }

  private addMarker(markerList: any[]) {
    this.removeAllMarkers();
    markerList.forEach((marker, index) => {
      const handler = (event: Event) => this.handleMarkerEvent(event, index);

      marker.getElement().addEventListener('mouseenter', handler);
      marker.getElement().addEventListener('mouseleave', handler);
      marker.getElement().addEventListener('click', handler);

      marker.getElement().__handlerRef = handler;
      marker.addTo(this.map);

      this.internalMarkerList.push(marker);
    });
  }

  private addPath(latlongList: [number, number][]) {
    this.polylineGeoJson = this.getRouteGeoJsonData(latlongList);
    this.map.setStyle('mapbox://styles/mapbox/' + this.currentLayer);
    this.map.on('styledata', () => {
      if (!this.map.getSource('route')) {
        this.map.addSource('route', {
          type: 'geojson',
          data: this.polylineGeoJson,
        });
      }
      if (!this.map.getLayer('line-background')) {
        // add a line layer without line-dasharray defined to fill the gaps in the dashed line
        this.map.addLayer({
          type: 'line',
          source: 'route',
          id: 'line-background',
          paint: {
            'line-color': 'green',
            'line-width': 8,
          },
          layout: {
            'line-join': 'round',
            'line-cap': 'round',
          },
        });
        const { fitBoundsOnPathChange = true } = this.customMapOptions;
        if (fitBoundsOnPathChange) {
          this.recenterMarkers();
        }
      }
    });
  }

  private getRouteGeoJsonData(coordinates: [number, number][]) {
    const data = {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'LineString',
            coordinates: coordinates,
          },
        },
      ],
    };
    return data;
  }

  private adjustMapWidth(windowWidth: number) {
    const viewType = this.tripDetailsService.tripDetailsViewType.getValue();
    if (windowWidth > 1440) {
      if (this.isSideNavOpen) {
        this.mapWidth = viewType === 'table' ? 'calc(100vw - 620px)' : '100vw';
      } else {
        this.mapWidth = viewType === 'table' ? 'calc(100vw - 400px)' : '100vw';
      }
    } else {
      this.mapWidth = viewType === 'table' ? 'calc(100vw - 400px)' : '100vw';
    }
    setTimeout(() => {
      this.map.resize();
      this.recenterMarkers();
    }, 100);
  }

  public recenterMarkers() {
    if (this.polylineGeoJson) {
      const bounds = turf.bbox(this.polylineGeoJson);
      const [minLng, minLat, maxLng, maxLat] = bounds;
      this.map.jumpTo({
        center: [(minLng + maxLng) / 2, (minLat + maxLat) / 2],
        zoom: this.map.getZoom(),
      });
      this.map.fitBounds(bounds, {
        padding: { top: 100, bottom: 100, left: 100, right: 100 },
      });
    } else {
      const coordinates = this.markerList.map((marker) => [marker._lngLat.lng, marker._lngLat.lat]);
      const bounds = coordinates.reduce(function (bounds, coord) {
        return bounds.extend(coord);
      }, new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]));
      this.map.jumpTo({
        center: bounds.getCenter(),
        zoom: this.map.getZoom(),
      });
    }
  }

  public moveToLocationIfNeeded(lat?: number, lng?: number, zoom?: number) {
    if (this.map) {
      const newCenter = [lng, lat];
      const newZoom = zoom || this.map.getZoom();
      const bounds = this.map.getBounds(); // Get the current visible area of the map

      // Check if the marker is inside the current viewport before recentering the live marker pin
      // to avoid always resetting if user if focusing on other area of the trip
      if (bounds.contains(newCenter)) {
        this.map.easeTo({
          center: newCenter,
          zoom: newZoom,
          essential: true, // This ensures a smooth animation
        });
      }
    }
  }

  public switchLayer(layerId: string) {
    this.currentLayer = layerId;
    this.map.setStyle('mapbox://styles/mapbox/' + this.currentLayer);
    this.map.on('styledata', () => {
      if (!this.map.getSource('route')) {
        this.map.addSource('route', {
          type: 'geojson',
          data: this.polylineGeoJson,
        });
      }

      if (!this.map.getLayer('line-background')) {
        // add a line layer without line-dasharray defined to fill the gaps in the dashed line
        this.map.addLayer({
          type: 'line',
          source: 'route',
          id: 'line-background',
          paint: {
            'line-color': 'green',
            'line-width': 8,
          },
          layout: {
            'line-join': 'round',
            'line-cap': 'round',
          },
        });
      }
    });
  }

  public updatePath(latLongList: [number, number][]) {
    const source = this.map.getSource('route');
    if (source) {
      this.polylineGeoJson = this.getRouteGeoJsonData(latLongList);
      source.setData(this.polylineGeoJson);
    } else {
      this.addPath(latLongList);
    }
  }
}
